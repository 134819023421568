<template>
  <div>
    <a-modal
      v-model="visibleModalAddEmployee"
      class="modal-add-employee"
      title="Tambah Pegawai"
      @cancel="toggleModalAddEmployee"
      :destroyOnClose="true"
    >
      <add-employee
       :dataInsentifPegawai="manageEmployeeData"
       :employeeColumns="employeeColumns"
       @close-modal="toggleModalAddEmployee"
      />
      <template slot="footer">
        <a-button class="d-none m-0" size="large" key="back" @click="toggleModalAddEmployee">
          Tutup
        </a-button>
      </template>
    </a-modal>
    <a-modal
      v-model="visibleModalFinalizeInsentif"
      class="mod"
      title="Tambah Pegawai"
      @cancel="toggleModalFinalizeInsentif"
      :destroyOnClose="true"
    >
      <a-row>
        <a-col :span="24">
          <a-form-item label="Tanggal Pembayaran Insentif">
            <a-date-picker
              v-model="dataInsentif.tanggal_pembayaran_insentif"
              format="YYYY-MM-DD"
              class="w-100"
              size="large"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <template slot="footer">
          <a-button size="large" key="back" @click="toggleModalFinalizeInsentif">
            Batal
          </a-button>
          <a-button size="large" key="submit" type="primary" :loading="loadingActionModalFinalizeInsentif" @click="finalizeInsentif">
            Finalisasi Sekarang
          </a-button>
        </template>
    </a-modal>
    <div class="d-flex">
      <div class="mr-3">
          <a-input
            v-model="search"
            @keyup="searchEmployee"
            size="large"
            placeholder="Cari pegawai..."
          />
        </div>
        <div class="mr-3">
          <a-button
          :disabled="!insentifEmployeeDataTable.length"
          v-if="manageEmployeeData.status === 'draft_hr'"
          @click.prevent="toggleModalFinalizeInsentif"
          class="ml-3"
          type="primary"
          size="large"
          :loading="loadingFinalizeInsentifEmployee"
        >
          <a-icon type="check" />{{loadingFinalizeInsentifEmployee ? 'Sedang Finalisasi...' : `Finalisasikan Insentif ${manageEmployeeData.nama}`}}
        </a-button>
        </div>
      <div v-if="manageEmployeeData.status === 'draft_hr'" class="ml-auto">
        <a-button
          @click.prevent="removeInsentifEmployeeSelected"
          :disabled="!selectedRowInsentifEmployee.length"
          class="ml-3"
          type="danger"
          size="large"
          :loading="loadingDeleteInsentifEmployee"
        >
          <a-icon v-if="!loadingDeleteInsentifEmployee" type="delete" /> {{loadingDeleteInsentifEmployee ? 'Menghapus...' : 'Hapus Insentif Pegawai Terpilih'}}
        </a-button>
        <a-button
          @click.prevent="toggleModalAddEmployee"
          class="ml-3"
          type="primary"
          size="large"
        >
          <a-icon type="plus" />Tambah Pegawai
        </a-button>
      </div>
    </div>
    <div class="mt-4">
      <a-table
        :columns="employeeColumns"
        :data-source="insentifEmployeeDataTable"
        :pagination="paginationInsentifEmployee"
        :loading="loadingTableInsentifEmployee"
        :row-selection="rowSelection"
        @change="handleTableChange"
        bordered
      >
        <div slot="jenis_kelamin" slot-scope="value">
          <span v-if="value"> {{value}}</span>
          <span v-else>-</span>
        </div>
        <div slot="unit" slot-scope="value">
          <span v-if="value"> {{value.nama}}</span>
          <span v-else>-</span>
        </div>
        <div slot="jabatan" slot-scope="value">
          <template v-if="value.length">
            <a-tag
              v-for="item in value"
              :key="item.id"
              color="geekblue"
            >
            {{ item.jabatan.nama.toUpperCase() }}
          </a-tag>
          </template>
          <template v-else>
            <span>-</span>
          </template>
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
const addEmployee = () => import('@/components/app/AdminHR/Insentif/ManageEmployee/AddEmployee')
const employeeColumns = [
  {
    title: 'ID Pegawai',
    dataIndex: 'nik',
    key: 'nik',
    // width: 250,
    scopedSlots: { customRender: 'nik' },
  },
  {
    title: 'Nama',
    dataIndex: 'nama',
    key: 'nama',
    scopedSlots: { customRender: 'nama' },
  },
  {
    title: 'Jenis Kelamin',
    dataIndex: 'jenis_kelamin',
    key: 'jenis_kelamin',
    // width: 200,
    sorter: true,
    scopedSlots: { customRender: 'jenis_kelamin' },
  },
  {
    title: 'Unit',
    dataIndex: 'unit',
    key: 'unit',
    // width: 200,
    scopedSlots: { customRender: 'unit' },
  },
  {
    title: 'Jabatan',
    dataIndex: 'jabatan',
    key: 'jabatan',
    // width: 200,
    scopedSlots: { customRender: 'jabatan' },
  },
]
export default {
  name: 'ManageEmployee',
  components: {
    addEmployee,
  },
  props: {
    manageEmployeeData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      employeeColumns,
      insentifEmployeeDataTable: [],
      selectedRowInsentifEmployee: [],
      paginationInsentifEmployee: {},
      visibleModalAddEmployee: false,
      loadingTableInsentifEmployee: false,
      loadingDeleteInsentifEmployee: false,
      loadingFinalizeInsentifEmployee: false,
      visibleModalFinalizeInsentif: false,
      loadingActionModalFinalizeInsentif: false,
      dataInsentif: {
        tanggal_pembayaran_gaji: null,
      },
      search: '',
    }
  },
  methods: {
    closeModal() {
      this.$emit('close-modal')
    },
    toggleModalFinalizeInsentif() {
      this.visibleModalFinalizeInsentif = !this.visibleModalFinalizeInsentif
    },
    finalizeInsentif() {
      this.$confirm({
        title: 'Peringatan',
        content: (
          <div class="d-flex flex-column"><p>Apakah anda yakin ingin finalisasi insentif {this.manageEmployeeData.nama}? </p><p class="text-danger mt-4">*Perubahan ini akan membuat anda tidak dapat menambah/menghapus insentif pegawai lagi</p></div>
        ),
        onOk: () => {
          this.loadingActionModalFinalizeInsentif = true
          this.$store.dispatch('insentif/PUBLISH_INSENTIF_HR', { id: this.manageEmployeeData.id, dataInsentif: this.dataInsentif })
            .then(isSuccess => {
              this.loadingActionModalFinalizeInsentif = false
              this.closeModal()
              if (isSuccess) {
                this.$notification.success({
                  message: 'Berhasil',
                  description: `Insentif ${this.manageEmployeeData.nama} berhasil di finalisasi`,
                })
                this.selectedRowInsentifEmployee = []
              } else {
                this.$notification.error({
                  message: 'Gagal',
                  description: `Insentif ${this.manageEmployeeData.nama} gagal di finalisasi`,
                })
                this.selectedRowInsentifEmployee = []
              }
            })
        },
        onCancel: () => {
        },
        centered: true,
        icon: 'warning',
        okType: 'primary',
        okText: 'Finalisasi Sekarang',
        cancelText: 'Batal',
      })
    },
    removeInsentifEmployeeSelected() {
      console.log(this.selectedRowInsentifEmployee)
      this.$confirm({
        title: 'Peringatan',
        content: (
          <div class="d-flex flex-column"><p>Apakah anda yakin ini menghapus {this.selectedRowInsentifEmployee.length} data shift pegawai?</p><p class="text-danger mt-4">*Perubahan ini akan mempengaruhi sistem penggajian pegawai</p></div>
        ),
        onOk: () => {
          this.loadingDeleteInsentifEmployee = true
          this.$store.dispatch('insentif/DELETE_INSENTIF_EMPLOYEE_SELECTED', { dataPegawai: this.selectedRowInsentifEmployee })
            .then(isSuccess => {
              this.loadingDeleteInsentifEmployee = false
              this.fetchDataInsentifEmployee()
              if (isSuccess) {
                this.$notification.success({
                  message: 'Berhasil',
                  description: `${this.selectedRowInsentifEmployee.length} data insentif pegawai berhasil di hapus`,
                })
                this.selectedRowInsentifEmployee = []
              } else {
                this.$notification.error({
                  message: 'Gagal',
                  description: 'data insentif pegawai gagal di hapus',
                })
                this.selectedRowInsentifEmployee = []
              }
            })
        },
        onCancel: () => {
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Hapus Sekarang',
        cancelText: 'Batal',
      })
    },
    toggleModalAddEmployee() {
      this.visibleModalAddEmployee = !this.visibleModalAddEmployee
      if (!this.visibleModalAddEmployee) {
        this.fetchDataInsentifEmployee()
      }
    },
    searchEmployee() {
      this.fetchDataInsentifEmployee({
        search: this.search,
      })
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.paginationInsentifEmployee }
      let order = 'ASC'
      let sortBy = ''
      pager.current = pagination.current
      this.paginationInsentifEmployee = pager
      if (sorter) {
        sortBy = sorter.field
        // console.log(sorter)
        if (sorter.order === 'ascend') {
          order = 'ASC'
        } else if (sorter.order === 'descend') {
          order = 'DESC'
        }
      }
      this.fetchDataInsentifEmployee({
        page: pagination.current,
        order,
        sortBy,
      })
    },
    fetchDataInsentifEmployee(params = { page: 1, order: 'ASC', search: '', type: '', sortBy: '' }) {
      this.loadingTableInsentifEmployee = true
      const id = this.manageEmployeeData.id
      this.$store.dispatch('insentif/FETCH_INSENTIF_EMPLOYEE', { id, page: params.page, order: params.order, search: params.search, sortBy: params.sortBy })
        .then(res => {
          // console.log(res)
          this.loadingTableInsentifEmployee = false
          const pagination = { ...this.paginationInsentifEmployee }
          pagination.total = res.total
          this.paginationInsentifEmployee = pagination
          this.insentifEmployeeDataTable = res.insentifPegawai.map(el => {
            return {
              key: el.id,
              id: el.id,
              nik: el.guru.nik,
              nama: el.guru.nama,
              jenis_kelamin: el.guru.jenis_kelamin,
              unit: el.guru.unit,
              jabatan: el.guru.jabatan_pegawais,
            }
          })
        })
    },
  },
  mounted() {
    this.fetchDataInsentifEmployee()
  },
  computed: {
    rowSelection() {
      if (this.manageEmployeeData.status !== 'draft_hr') {
        return
      }
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
          this.selectedRowInsentifEmployee = selectedRows
        },
        // getCheckboxProps: record => ({
        //   props: {
        //     disabled: record.key === 34, // Column configuration not to be checked
        //     name: record.name,
        //   },
        // }),
      }
    },
  },
}
</script>

<style>

</style>
